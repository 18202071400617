@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');

.boxFeature{
    background-color: black;
    padding: 0px 40px;
}

.boxRelative{
    position: relative;
}

.headingFeature{
    color: white;
    position: absolute;
    top: 30px;
    left: 30px;
    font-weight: 800;
    font-size: 24px;
}
.headingTitleBox{
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
}
.headingCenter{
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-weight: 800;
    font-size: 65px;
    opacity: 0.62;
}
.imageFeature{
    width: 100%;
}
.box2Feature{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 80px 0px;
    gap: 30px;
}
.cardTitle{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    font-size: 22px;
}

.cardSubTitle{
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
}
.lineFeature{
    display: none;
}
@media only screen and (max-width:700px){
.boxFeature{
    padding: 0px;
}
.imageFeature{
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
.headingCenter{
    font-size: 40px;
}
.box2Feature{
    flex-direction: column;
    padding: 10px 20px;
}
.lineFeature{
    display: block;
    height: 1px;
    background-color: white;
    width: 100%;
    margin: 20px 0px;
}
}