@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');

.containerInfo{
    display: flex;
    flex-direction: column;
    position: relative;
}
.box1{
   background-color: black; 
}
.boxInfo2{
    background-color: white;
    display: flex;
    flex-direction: column;
}
.headingInfo{
    color: white;
    padding-top: 20px;
    padding-left: 50px;
    font-size: 35px;
    font-weight: 700;
}
.space10{
    height: 10vh;
}


.statboxAlign{
    display: flex;
    gap: 20px;
    padding: 0px 0px 10vh 50px;
}

.imageInfo1{
    position: absolute;
    right: -10px;
    top: 10px;
}

.imageInfo2{
    position: absolute;
    left: 10px;
    bottom: 5vh;
}
.wod{
    font-weight: 800;
    font-size: 100px;
    color: #000000;
    opacity: 0.18;
    position: absolute;
    left: 26vw;
}
.headingInfo2{
    color: black;
    padding-bottom: 20px;
    font-weight: 800;
}

.subheadingInfo{
    color: black;
    font-weight: 300;
    font-size: 18px;
    
}

.available{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;  
    padding-top: 20px;
}


.statHeading{
color: white;
font-size: 16px;
font-family: 'Inconsolata', monospace;
font-weight: 700;
}

.statCount{
color: white;
font-size: 30px;
font-weight: 700;
}
.info2Content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.statBox{
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1C1C1C;
    border-radius: 10px;
    padding: 20px 0px;
}
.textBoxInfo{
    padding: 80px 120px;
    display: flex;
    flex-direction: column;
}
.imageInfoPhone{
    display: none;
}

.gridInfo{
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px;
}
.featureBoxInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 120px;
}

.infoBoxInfo{
    display: flex;
    gap: 10px;
}
.titleInfo{
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 13px;
}
@media only screen and (max-width:700px){
    .gridInfo{
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: flex-start;
    }
}
@media only screen and (max-width:820px){
    .containerInfo{
        width: 100%;
    }
    
    .imageInfo1{
        display: none;
    }
    .imageInfo2{
      display: none;
    }
    .imageInfoPhone{
        display: flex;
        justify-content: center;
        height: 50%;
    }
    .statboxAlign{
        padding: 0px 0px 50px 0px;
        justify-content: center;

    }
    .statBox{
        width: 40vw;
        padding: 20px 10px;
    }
    .statHeading{
        font-size: 14px;

    }
    .statCount{
        font-size: 24px;
    }
    .info2Content{
        flex-direction: column;
    }
    .wod{
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
    }
    .textBoxInfo{
        padding: 20px;
        text-align: center;
    }
    .headingInfo2{
        font-size: 28px;
    }
    .subheadingInfo{
        margin: auto;
        font-size: 15px;
        text-align: start;
    }

    .featureBoxInfo{
        justify-content: start;
        padding: 10px;
    }
}
