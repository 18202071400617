@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');

.termContainer{
    padding: 50px;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.termHeading{
    font-weight: 800;
    font-size: 32px;
}

.termPara{
    font-weight: 400;
    font-family: 'Inconsolata', monospace;
}
.copyWrite{
     font-weight: 700;
     font-size: 14px;
    font-family: 'Inconsolata', monospace;
}

.text-lg {
    font-size: large;
}

.copyBox{
    padding: 30px;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:700px){
    .termContainer{
        padding: 25px;
        gap: 10px;
    }
    .termHeading{
        font-size: 26px;
    }
    .termPara{
        font-size: 15px;
    }
}