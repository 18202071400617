@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');

::placeholder{
    color: #777777;
    font-weight: 400;
    font-family: 'Inconsolata', monospace;
}

.container1{  
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-right: 0px;
    display: grid;
    height: 100vh;
    grid-template-columns: 50% 50%;
}

.box{
    position: relative;
    height: 100vh;
}

.image{
    position:absolute;
    object-fit: cover;
    height: 100vh;
}

.form-box{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.heading1{
    color: black;
    font-size: 28px;
    font-weight: 800;
}

.title{
    font-weight: 300;
    font-size: 24px;
}

.feature-box{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 80%;
}

.form{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 20px;
}

.textfield1{
    border: 2px solid black;
    border-radius: 8px;
    padding: 15px 10px;
}


.textfield1:focus {
    outline: none;
}


.button1{
    width: 90%;
    border-radius: 50px;
    padding: 15px 10px;
    background-color:#64BDFF ;
    border: none;
    color: white;
    font-weight: 800;
    font-family: 'Inconsolata', monospace;
}

.button1:hover{
    background-color:#64BDFF ;
}

.sent-box{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.heading2{
    font-size: 20px;
    font-weight: 700;
}

.box4h{
    height: 4vh;
}

.logoL{
    display: none;
}

@media only screen and (max-width:1000px){
    .container1{
        grid-template-columns: 40% 60%;
    }
}

@media only screen and (max-width:700px){
    .box{
        display: none;
    }

    .form-box{
    padding: 0px 10px;
    width: 90vw;
    padding: 30px 0px;
    justify-content: start;
    align-items: center;
    }
    .heading1{
    font-weight: 300;
    font-size: 16px;
    }
    .title{
    font-weight: 300;
    font-size: 16px;
    }
    .textfield1{
        padding: 10px;
    }
    .button{
        padding: 10px;
    }

    .logoL{
        justify-content: start;
        display: block;
    }

    .sent-box{
    padding: 0px 10px;
    width: 90vw;
    padding: 30px 0px;
    justify-content: start;
    align-items: center;
    }
    .box4h{
        display: none;
    }
}
