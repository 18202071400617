.boxSlider{
    border-top: 2px solid black;
    padding: 20px 0px ;
}

.sliderHeading{
    color: #8E8E8E;
    font-weight: 800;
    font-size: 30px;
    padding-left: 50px;
    padding-bottom: 10px;
}