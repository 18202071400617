.boxFooter{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F7F7F7;
    padding-bottom: 20px;  
}

.box2Footer{
    border-top: black 2px solid;
    display: flex;
    justify-content: space-between;
    padding: 2vh 5% 0px 5%;
    align-content: flex-start;
}

.titleFooter{
    font-weight: 700;
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
}

.subHeadingFooter{
    font-weight: 400;
    font-family: 'Inconsolata', monospace;
    font-size: 14px;
    color: black;
    text-decoration: none;
}

.copyWrite{
    font-weight: 700;
    font-size: 14px;
   font-family: 'Inconsolata', monospace;
}

.social-icon{
    display: none;
}
@media only screen and (max-width:700px){
    .box2Footer{
        padding-top: 50px;
        border-top: none;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
    }

    .copyWrite{
        padding-top: 50px;
    }

    .social-text{
        display: none;
    }
    .social-icon{
        display: flex;
        gap:20px;
    }
}