::placeholder{
    color: #DDDDDD;
}
.font-family{
    font-family: 'Inconsolata', monospace;
}
.flexBox{
    display: flex;
    width: 100%;
}

.contact-box{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 25px;
    text-align: center;
}
.faq-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items:center;
}
.faq-box{
    width: 70%;
    border-left: black 1px solid;
}

.text-field-faq{
    border: none;
    width: 100%;
}

.text-field-faq:focus{
    outline: none;
}

.button-faq{
    background-color: #64BDFF;
    border: 0px solid transparent;
    border-radius: 50px;
    padding: 10px;
    color: white;
    width: 50%;
    font-size: 16px;
}

.contact-us{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    color: #707070;
    padding-bottom: 20px;
}

.faq-heading{
    font-weight: 700;
    padding: 10px;
    font-size: 24px;
}

.accordian-heading{
    font-weight: 700;
    font-size: 17px;
}

@media only screen and (max-width:1000px){
    .flexBox{
        flex-direction: column-reverse;
    }
    .faq-box{
        width: 100%;
    }
    .contact-box{
        width: 100%;
    }
    .contact-us{
        text-align: center;
    }
    .button-faq{
        width: 50%;
        font-size: 14px;
    }
}