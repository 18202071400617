@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');

.boxRelative{
    position: relative;
    color: white;
    width: 100%;
}

.boxWod{
    position: absolute;
    top:30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.background{
    width: 100%;
    height: 60vh;
}
.scanBox{
    width: 33%;
    padding-left: 50px;
}
.heading1Wod{
   font-weight: 700;
    font-size: 35px;
    padding: 15px 0px;
}

.paraWod{
    font-weight: 300;
    font-size: 18px;
    padding: 15px 0px;
}
.smallText{
    font-weight: 300;
    font-size: 12px;
}

.priceBox{
    margin-bottom: 0px;
    width: 33%;
    color: white;
    background-color: black;
    text-align: center;
    padding: 20px 50px;
}

.priceText{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
}

.headingWodF{
    font-size: 16px;
    font-weight: 700;
}

.subTitleWod{
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 12px;
}
.blackBox{
    background-color: black;
    height: 30vh;
    display: none;
}
.device{
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    height: 50vh;
    margin: auto;
}
.active{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    border-bottom: 2px solid #64BDFF;
}
.inactive{
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
}
@media only screen and (max-width:800px){
    .blackBox{
        display: block;
    }
    .background{
        object-fit: cover;
        height: 50vh;
    }
    .device{
        position: absolute;
        
        bottom: 0px;
        height: 40vh;
        top: 20vh;
    }
    .priceBox{
        display: none;
    }
    .boxWod{
        flex-direction: column;
    }
    .scanBox{
        width: 100%;
        text-align: center;
        padding: 0px;
    }
}