@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;800&display=swap');
::placeholder{
    color: #DDDDDD;
}
.boxLanding{
    position: relative;
}

.heading{
    position: absolute;
    top: 10px;
    right: 50px;
    color: white;
    text-align: end;
}
.imageLanding{
    height: 60vh;
    width: 100%;
    object-fit: cover;
}
.caption-box{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    left: 50px;
}

.caption-heading{
    width: 50%;
    color: white;
    text-align: start;
    font-size: 15px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    padding-bottom: 20px;
}

.w800{
    font-weight: 800;
}

.textfieldLanding{
    width: 70%;
    background-color: transparent;
    border: 0px;
   color: #000;
}

.textfieldLanding:focus{
    outline: none;
}

.form-box-landing{
    display: flex;
    background-color: white;
    padding: 0px 0px 0px 20px;
    border-radius: 50px;
}

.buttonLanding{
    width: 30%;
    background:#64BDFF;
    border:black;
    border-radius:28px;
    padding:15px 0px;
    font-size:15px;
    font-weight:lighter;
    color: white;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    
}

.buttonLanding:hover{
    background-color:#64BDFF ;
}
@media only screen and (max-width:700px){
    .imageLanding{
        height: 40vh;
    }
    .caption-heading{
        width: 70%;
    }
    .caption-box{
        width: 90%;
        position: absolute;
        left: 10px;
    }
}