@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap');

* {
    scroll-behavior: smooth;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; 
  border-bottom: none; 
}

.navbar{
   border-bottom: black 4px solid;
   background-color: white;
}

.container{
    display: flex;
    justify-content: space-between;
}

.nav{
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-family: 'Inconsolata', monospace;
    font-size: 18px;
}

.button{
    background:black;
    border:black;
    border-radius:28px;
    padding:5px 25px;
    font-size:15px;
    font-weight:lighter;  
}

.button:hover{
    background-color: black;
}

.logo{
    width:180px;
}

.menu{
    display: block;
}

@media only screen and (max-width:700px){
    .logo{
        width: 120px;
    }

    .button{
        font-size: 14px;
    }
    .menu{
        display: none;
    }
}